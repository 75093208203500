import { createRouter, createWebHistory } from 'vue-router'


// Auth Default Routes
const authChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.login',
    meta: { auth: false, name: 'Login' },
    component: () => import('@/views/auth/default/SignIn.vue')
  },
  {
    path: 'register',
    name: prefix + '.register',
    meta: { auth: false, name: 'Register' },
    component: () => import('@/views/auth/default/SignUp.vue')
  },
  {
    path: 'reset-password',
    name: prefix + '.reset-password',
    meta: { auth: false, name: 'Reset Password' },
    component: () => import('@/views/auth/default/ResetPassword.vue')
  },
  {
    path: 'varify-email',
    name: prefix + '.varify-email',
    meta: { auth: false, name: 'Varify Email' },
    component: () => import('@/views/auth/default/VarifyEmail.vue')
  },
  {
    path: 'lock-screen',
    name: prefix + '.lock-screen',
    meta: { auth: false, name: 'Lock Screen' },
    component: () => import('@/views/auth/default/LockScreen.vue')
  }
]

//Landing pages route
const landingPageRoutes = (prefix) => [
  {
    path: '',
    name: prefix + 'landing-page',
    meta: { auth: true, name: 'Landing Page', isBanner: true, header: 'header_two', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/HomePage.vue')
  },
  {
    path: '/about-us',
    name: prefix + 'about-us',
    meta: { auth: true, name: 'About Us', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/AboutUs.vue')
  },
  {
    path: '/blogs',
    name: prefix + 'blogs',
    meta: { auth: true, name: 'Blogs', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/BlogPage')
  },
  {
    path: '/blog-details',
    name: prefix + 'blog-details',
    meta: { auth: true, name: 'Blogs', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/BlogDetail')
  },
  {
    path: '/contact-us',
    name: prefix + 'contact-us',
    meta: { auth: true, name: 'Contact Us', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ContactUs')
  },
  {
    path: '/e-commerce',
    name: prefix + 'e-commerce',
    meta: { auth: true, name: 'E-Commerce', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ECommerce.vue')
  },
  {
    path: '/shop',
    name: prefix + 'shop',
    meta: { auth: true, name: 'Shop', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ShopPage')
  },
  {
    path: '/shop-detail',
    name: prefix + 'shop-detail',
    meta: { auth: true, name: 'Shop Detail', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/ShopDetails')
  },
  {
    path: '/faq',
    name: prefix + 'faq',
    meta: { auth: true, name: 'FAQ', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/FAQ')
  },
  {
    path: '/features',
    name: prefix + 'features',
    meta: { auth: true, name: 'Features', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/FeaturesPage.vue')
  },
  {
    path: '/landing-pricing',
    name: prefix + 'pricing',
    meta: { auth: true, name: 'Pricing', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/PricingPage')
  },
  {
    path: '/saas-marketing',
    name: prefix + 'sass-marketing',
    meta: { auth: true, name: 'Sass Marketing', isBanner: true, header: 'header_one', footer: 'footer' },
    component: () => import('@/views/modules/landing-pages/SassMarketingLandingPage.vue')
  },
  {
    path: '/software',
    name: prefix + 'software',
    meta: { auth: true, name: 'Software Landing Page', isBanner: true, header: 'header_one', footer: 'footer-one' },
    component: () => import('@/views/modules/landing-pages/SoftwareLandingPage.vue')
  },
  {
    path: '/start-up',
    name: prefix + 'start-up',
    meta: { auth: true, name: 'Start Up', isBanner: true, header: 'header_one', footer: 'footer_one' },
    component: () => import('@/views/modules/landing-pages/StartupLandingPage')
  }
]

// Default routes
const defaultChildRoutes = (prefix) => [
  {
    path: '',
    name: prefix + '.dashboard',
    meta: { auth: true, name: 'Home', isBanner: false },
    component: () => import('@/views/dashboards/IndexPage.vue')
  },
  {
    path: '/reserved-instance',
    name: prefix + '.settings',
    meta: { auth: true, name: 'Alternate Dashboard', isBanner: false },
    component: () => import('@/views/dashboards/ReservedInstance.vue')
  },
  {
    path: '/saving-plan',
    name: prefix + '.savings',
    meta: { auth: true, name: 'Savings', isBanner: false },
    component: () => import('@/views/dashboards/SavingsSuggestions.vue')
  },
  // Extra Pages
  {
    path: '/privacy-policy',
    name: prefix + '.privacy-policy',
    meta: { auth: true, name: 'Privacy Policy', isBanner: true },
    component: () => import('@/views/extra/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: prefix + '.terms-and-conditions',
    meta: { auth: true, name: 'Terms and Conditions', isBanner: true },
    component: () => import('@/views/extra/TermsAndConditions.vue')
  },
]

const errorRoutes = (prefix) => [
  // Error Pages
  {
    path: '404',
    name: prefix + '.404',
    meta: { auth: true, name: 'Error 404', isBanner: true },
    component: () => import('@/views/errors/Error404Page.vue')
  },
  {
    path: '500',
    name: prefix + '.500',
    meta: { auth: true, name: 'Error 500', isBanner: true },
    component: () => import('@/views/errors/Error500Page.vue')
  },
  {
    path: 'maintenance',
    name: prefix + '.maintenance',
    meta: { auth: true, name: 'Maintenance', isBanner: true },
    component: () => import('@/views/errors/MaintenancePage.vue')
  }
]

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../layouts/DefaultLayout.vue'),
    children: defaultChildRoutes('default'),
    meta: { requiresAuth: true }
  },
  {
    path: '/landing-page',
    name: 'landing-page',
    component: () => import('../layouts/LandingPageLayout.vue'),
    children: landingPageRoutes('landing-page')
  },
  {
    path: '/',
    name: 'auth',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: authChildRoutes('auth')
  },
  {
    path: '/errors',
    name: 'errors',
    component: () => import('../layouts/guest/BlankLayout.vue'),
    children: errorRoutes('errors')
  },
];

const router = createRouter({
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem('jwtToken');

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});



export default router
