<template>
  <!--Logo start-->
  <div class="logo-main">
    <div class="logo-normal">
      <img
        v-if="!isImage"
        :class="`${color ? 'text-primary' : ''} icon-30`"
        src="../../../../src/assets/images/favicon.png"
        alt="Logo"
      />
      <svg
        v-else
        :class="`${color ? 'text-primary' : ''} icon-30`"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
        <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
        <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
        <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
      </svg>
    </div>
    <div class="logo-mini">
      <img
        v-if="!isImage"
        :class="`${color ? 'text-primary' : ''} icon-30`"
        src="../../../../src/assets/images/favicon.png"
        alt="Logo"
      />
      <svg
        v-else
        :class="`${color ? 'text-primary' : ''} icon-30`"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="-0.757324" y="19.2427" width="28" height="4" rx="2" transform="rotate(-45 -0.757324 19.2427)" fill="currentColor" />
        <rect x="7.72803" y="27.728" width="28" height="4" rx="2" transform="rotate(-45 7.72803 27.728)" fill="currentColor" />
        <rect x="10.5366" y="16.3945" width="16" height="4" rx="2" transform="rotate(45 10.5366 16.3945)" fill="currentColor" />
        <rect x="10.5562" y="-0.556152" width="28" height="4" rx="2" transform="rotate(45 10.5562 -0.556152)" fill="currentColor" />
      </svg>
    </div>
  </div>
  <!--Logo End-->

</template>

<script>
export default {
  props: {
    color: { type: Boolean, default: false },
    isImage: { type: Boolean, default: false }, // Add a new prop to determine if the logo is an image
  },
};
</script>
